<template>
  <div class="inicial-container-safra-conceito">
    <h5 class="title">
      Taxa de Evento por Safra:
      <span class="material-symbols-outlined helpIcon">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Exibe a taxa de Maus em cada <strong>Safra</strong> de acordo com a
          <strong>Data de Referência</strong> mapeada.
        </md-tooltip>
      </span>
    </h5>
    <div class="cardDataBox">
      <p
        class="chartTitle"
        v-if="liberaSafraDataRef && !errorMsgResposta && valido"
      >
        <strong>Taxa de Evento por Safra</strong>
      </p>
      <div class="chart">
        <div
          class="chartBox"
          id="mixedChartDefault"
          v-if="
            liberaSafraDataRef &&
            !varResposta &&
            !errorMsgResposta &&
            !liberaLoad
          "
        >
          <MixedVazio :id="2" />
        </div>
        <div
          class="chartBox"
          v-if="
            liberaSafraDataRef &&
            varResposta &&
            !errorMsgResposta &&
            liberaChart &&
            valido
          "
        >
          <MixedSafraConceito
            :id="4"
            :eventoPorSafra="eventoPorSafra"
            :respMau="respMau"
            :key="respMau"
          />
        </div>
        <md-card
          class="cardError"
          v-if="varResposta && !errorMsgResposta && !valido"
        >
          <span class="material-symbols-outlined icon">fmd_bad</span>
          <h5 class="task errorAlert">
            Selecione uma <strong>Variável Resposta</strong> válida
          </h5>
        </md-card>
        <md-card class="cardError" v-if="errorMsgResposta && !varResposta">
          <span class="material-symbols-outlined icon-error task"
            >highlight_off</span
          >
          <h5 class="task errorAlert">{{ errorMsgResposta }}</h5>
        </md-card>
      </div>
    </div>
    <div class="boxDisable" v-if="!liberaSafraDataRef">
      <md-card class="cardDisable">
        <span class="material-symbols-outlined icon">fmd_bad</span>
        <p class="textData errorAlert">
          As informações relacionadas à <strong>Safra</strong> estão
          <strong>desabilitadas</strong> pois não foi mapeada uma
          <strong>Data de Referência</strong> para este Lote.
        </p>
      </md-card>
    </div>
  </div>
</template>

<script>
import { historicoService } from "@/services";
import { useSkynetStore } from "@/stores/skynet";
import { mapWritableState, mapActions } from "pinia";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import MixedSafraConceito from "@/components/Integracao/Charts/MixedSafraConceito";
import MixedVazio from "@/components/Integracao/Charts/MixedVazio";

export default {
  name: "SafraConceito",

  components: {
    MixedSafraConceito,
    MixedVazio,
  },

  props: {
    identificador: String,
    varRespSelecionada: String,
    varResposta: Object,
    errorMsgResposta: String,
    cabecalhoMapeadoAPI: Array,
    cabecalhoMapeadoUser: Array,
    liberaSafraDataRef: Boolean,
    valido: Boolean,
    liberaLoad: Boolean,
  },

  data() {
    return {
      liberaChart: false,
      eventoPorSafra: null,
    };
  },

  methods: {
    async recuperaEventoPorSafra() {
      const objetoHistorico = {
        identificacao_execucao: this.identificador,
        coluna_safra: this.dataRefColumn,
        formato_safra: this.formatData,
        coluna_principal: this.varRespSelecionada,
        ignorar_valores_NA_principal: true,
        limite_contagem: 50,
      };
      const query = new URLSearchParams(objetoHistorico);
      try {
        this.eventoPorSafra = await historicoService.recuperaTaxaEventoPorSafra(
          query.toString()
        );
        this.liberaChart = true;
      } catch (error) {}
    },
  },

  created() {},

  computed: {
    ...mapWritableState(useSkynetStore, ["formatData", "dataRefColumn"]),
    ...mapWritableState(useSkynetMemoryStore, ["respMau"]),
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container-safra-conceito {
  // height: 50%;
  width: 100%;
  @include mediaQueryMax(small) {
    max-width: 100%;
    max-height: 60%;
  }
}

.title {
  margin: 10px 0;
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 2rem;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}
.cardDataBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cardData {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mediaQueryMax(medium) {
    max-width: 100%;
    max-height: 60%;
  }
  @include mediaQueryMax(small) {
  }
}

.textData {
  margin: 0 !important;
}

.chart {
  width: 100%;
  padding: 0%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
}

.chartBox {
  width: 100%;
  @include flex-center(column);
  align-items: center;
  justify-content: flex-start;
  transition: 0.5s ease all;
}
.cardError {
  width: 100%;
  height: 100px;
  max-height: 200px;
  padding: 10px;
  box-sizing: border-box;

  @include flex-center(column);

  @include mediaQueryMax(small) {
    width: 100%;
    height: 120px;
    margin-top: 1rem;
    margin-bottom: 9rem;
  }
}
.icon-error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 2rem !important;
}
.task {
  display: inline-block;
}
.errorAlert {
  width: 90%;
  margin: 10px 5px 10px 5px !important;
  box-sizing: border-box;
  font-size: 1rem !important;
  word-break: break-word;
  white-space: pre-line;
}
.chartTitle {
  margin: 10px;
}
.boxDisable {
  display: flex;
  justify-content: center;
}
.cardDisable {
  width: 80%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorAlert {
  width: 90%;
  margin: 10px 5px 10px 5px !important;
  box-sizing: border-box;
  font-size: 1rem !important;
  word-break: break-word;
  white-space: pre-line;
}
.icon {
  color: #209cee;
  font-size: 2rem !important;
}
</style>