<template>
  <div
    class="opcaoLinguagemBox"
    :style="
      clicked == language
        ? 'box-shadow: #209cee 0px 3px 8px;background-color:#209cee;color:white'
        : 'box-shadow: gray 0px 1px 4px;background-color:white;color:#209cee'
    "
    @click="selecaoLinguagem(language)"
  >
    <div
      class="imgOpcao"
      :style="
        clicked == language
          ? 'border-bottom: 1px solid white;'
          : 'border-bottom: 1px solid #209cee;'
      "
    >
      <img
        :src="clicked == language ? logoLight : logo"
        class="logosAbordagemIntegracao"
        alt="python logo"
      />
    </div>
    <span class="textOpcao">{{ description }}</span>
  </div>
</template>

<script>
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "OpcaoLinguagem",

  components: {},

  props: {
    logo: String,
    logoLight: String,
    language: String,
    clicked: String,
    description: String,
  },

  data() {
    return {};
  },
  methods: {
    selecaoLinguagem(linguagem) {
      this.setLiguagem(linguagem);
      this.$emit("recebeLing", linguagem);
    },
    ...mapActions(useSkynetMemoryStore, ["setLiguagem"]),
  },
  computed: {
    ...mapWritableState(useSkynetMemoryStore, ["linguagemMemory"]),
  },
  created() {
    if (this.linguagemMemory != "") {
      this.linguagem = this.linguagemMemory;
      this.selecaoLinguagem(this.linguagem);
    }
  },
};
</script>

<style lang="scss" scoped>
.opcaoLinguagemBox {
  width: 5.5rem;
  height: 5.5rem;
  background-color: #209cee;
  padding: 0.5rem;
  @include flex-center(column);
  border-radius: 10px;
  cursor: pointer;
}
.imgOpcao {
  width: 100%;
  height: 3.7rem;
  // background-color: red;
  border-bottom: 1px solid lightgray;

  padding: 0.3rem;
}
.logosAbordagemIntegracao {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.textOpcao {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.8rem;
  font-weight: 500;
  // color: white;
}
</style>