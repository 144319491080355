<template>
  <div class="inicial-container-covar">
    <div class="labelCovar">
      <h5 class="covarTitle">
        Limitar Covariáveis Explicativas:
        <span class="material-symbols-outlined helpIcon">
          help
          <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
            Atributos que serão utilizados no processo de modelagem.
            <br />
            Se <strong>desativada</strong>, serão realizadas análises com todas
            as demais variáveis, exceto as
            <strong>Variáveis Reservadas</strong> e as selecionadas nos inputs
            anteriores.
          </md-tooltip>
        </span>
      </h5>
      <b-form-checkbox
        type="checkbox"
        class="checkConfirm"
        id="checkConfirm"
        @click="checkCovariavel = !checkCovariavel"
        @change="loadSelectCovar()"
        v-model="checkCovariavel"
        switch
        size="lg"
      />
    </div>
    <md-field class="selectCol">
      <label for="covarSelecionada">Selecione as Covariáveis</label>
      <md-select
        name="covarSelecionada"
        id="covarSelecionada"
        multiple
        @input="verificaVariaveisReservadas()"
        v-model="covarSelecionadas"
        :placeholder="
          this.covarSelecionadasMemory.length != 0
            ? this.covarSelecionadasMemory + ' (Selecionadas)'
            : optionsListCovariavel <= 1
            ? 'Nenhuma coluna identificada'
            : 'Selecione as Covariáveis'
        "
        :disabled="!checkCovariavel"
      >
        <div class="textSearch">
          <md-input
            v-model="searchValueCovariavel"
            class="textSearchInput"
            placeholder="Procure uma coluna"
          >
          </md-input>
        </div>
        <md-option
          v-for="item in optionsListCovariavel"
          :key="item"
          :value="item"
        >
          {{ item }}
        </md-option>
      </md-select>
    </md-field>
    <div class="selectedCols" v-if="checkCovariavel">
      <div v-if="covarSelecionadas.length != 0">
        <strong class="selectedTitle">
          Colunas consideradas na modelagem:
        </strong>
        <div class="boxReservadas">
          <div v-for="atr in covarSelecionadas" :key="atr">
            <li class="text-left">
              {{ atr }}
            </li>
          </div>
          <Loading class="text-center spinner" v-if="libera" />
        </div>
      </div>
    </div>
    <div class="boxDisable" v-if="!checkCovariavel">
      <md-card class="cardCovarDisable">
        <span class="material-symbols-outlined icon">fmd_bad</span>
        <strong class="errorAlert">
          O Processo de modelagem será realizado com todas as variáveis, exceto
          as Variáveis Reservadas e as selecionadas nos inputs anteriores
        </strong>
      </md-card>
    </div>
  </div>
</template>

<script>
import { lotesService } from "@/services";
import { mapWritableState, mapActions } from "pinia";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import Loading from "@/components/Loading";

export default {
  name: "Covariaveis",

  components: {
    Loading,
  },
  props: {
    projectName: String,
    cabecalhoSaidaSelect: Array,
    libera: Boolean,
  },

  data() {
    return {
      covarSelecionadas: [],
      identificador: this.$route.params.id,
      cabecalhoSaida: [],
      reservadasAtualizadas: [],
      searchValueCovariavel: "",
      checkCovariavel: false,
    };
  },

  methods: {
    verificaVariaveisReservadas() {
      this.setCovarReservadas(this.covarSelecionadas);
      this.setCovarSelecionadas(this.covarSelecionadas);
    },

    loadSelectCovar() {
      this.$emit("checkCovar", this.checkCovariavel);
      if (this.checkCovariavel == false) {
        this.setCovarReservadas([]);
        this.covarSelecionadas = [];
      } else {
        this.setCovarSelect(this.varReservadas);
      }
    },

    ...mapActions(useSkynetStore, [
      "setCovarReservadas",
      "setCovarSelect",
    ]),
    ...mapActions(useSkynetMemoryStore, ["setCovarSelecionadas"]),
  },

  computed: {
    ...mapWritableState(useSkynetStore, [
      "selectedReservadas",
      "selectCovar",
      "varReservadas",
      "covarReservadas",
    ]),
    ...mapWritableState(useSkynetMemoryStore, ["covarSelecionadasMemory"]),

    optionsListCovariavel() {
      if (this.searchValueCovariavel.trim().length > 0) {
        return this.selectCovar.filter((column) =>
          column.toLowerCase().includes(this.searchValueCovariavel.trim())
        );
      }
      return this.selectCovar;
    },
  },

  created() {
    if (this.covarSelecionadasMemory.length != 0) {
      this.checkCovariavel = true;
      this.loadSelectCovar();
      this.covarSelecionadas = this.covarSelecionadasMemory;
      this.verificaVariaveisReservadas();
      this.searchValueCovariavel = "";
    }
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container-covar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}
.labelCovar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}
.covarTitle {
  font-size: 1rem;
  margin: 10px 0 0 0;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}
.selectCol {
  width: 80%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  float: left;
  cursor: pointer;

  @include mediaQueryMax(small) {
    width: 100%;
  }
}

.selectedCols {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 80%;
  min-width: 60%;
  background-color: transparent;

  @include mediaQueryMax(medium) {
    min-width: 90%;
    max-width: 100%;
  }
  @include mediaQueryMax(small) {
    max-width: 100%;
  }
}
.selectedTitle {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 10px 0;
}

.boxReservadas {
  border: 2px dashed var(--accent-60);
  border-radius: 10px;
  overflow-y: scroll;
  background-color: transparent;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  word-break: break-all;
  min-width: 90%;
}

.spinner {
  position: relative;
  left: 30%;
  top: -40%;
  // width: 100%;
  background-color: transparent;
  z-index: 10;
}

.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}
.boxDisable {
  display: flex;
  justify-content: center;
}
.cardCovarDisable {
  width: 80%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorAlert {
  width: 90%;
  margin: 10px 5px 10px 5px !important;
  box-sizing: border-box;
  font-size: 1rem !important;
  word-break: break-word;
  white-space: pre-line;
}

.icon {
  color: #209cee;
  font-size: 2rem !important;
}
</style>