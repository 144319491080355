<template>
  <div class="abordagemBox">
    <span class="tituloAbordagem">
      Selecione a Linguagem*
      <span class="material-symbols-outlined helpIcon">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Selecione a <strong>Linguagem de Deploy</strong> para ser utilizada na
          documentação.
          <br />
        </md-tooltip>
      </span>
    </span>
    <div class="opcoesLinguagem">
      <OpcaoLinguagem
        :logo="pythonLogo"
        :logoLight="pythonLogoLight"
        :language="'python'"
        :clicked="clicked"
        :description="'Python'"
        @recebeLing="recebeLing"
      />
      <OpcaoLinguagem
        :logo="sqlLogo"
        :logoLight="sqlLogoLight"
        :language="'sql'"
        :clicked="clicked"
        :description="'SQL'"
        @recebeLing="recebeLing"
      />
      <OpcaoLinguagem
        :logo="rLogo"
        :logoLight="rLogoLight"
        :language="'r'"
        :clicked="clicked"
        :description="'R'"
        @recebeLing="recebeLing"
      />
    </div>
    <span class="tituloAbordagem">
      Selecione as Abordagens Preditivas*
      <span class="material-symbols-outlined helpIcon">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66 text-left">
          Selecione <strong>uma ou mais Abordagens Preditivas</strong> para
          serem usadas na modelagem. <br />
          <li>
          <strong>Logistic Reg.</strong> executará uma modelagem clássica utilizando regressão
            logística. <br />
          </li>
          <li>
          <strong>LightGBM e XGBoost</strong> executarão uma modelagem através dos algoritmos
            de Machine Learning
          </li>
        </md-tooltip>
      </span>
    </span>
    <div class="opcoesLinguagem">
      <OpcaoAbordagem
        :logo="classicLogo"
        :logoLight="classicLogoLight"
        :abordagem="'classic'"
        :clicked="abordagem['classic']"
        :description="'Logistic Reg.'"
        :abordagemMemory="abordagemMemory"
        @recebeAbordagem="recebeAbordagem"
      />
      <OpcaoAbordagem
        :logo="lgLogo"
        :logoLight="lgLogoLight"
        :abordagem="'lg'"
        :clicked="abordagem['lg']"
        :description="'LightGBM'"
        :abordagemMemory="abordagemMemory"
        @recebeAbordagem="recebeAbordagem"
      />
      <OpcaoAbordagem
        :logo="xgLogo"
        :logoLight="xgLogoLight"
        :abordagem="'xg'"
        :clicked="abordagem['xg']"
        :description="'XGBoost'"
        :abordagemMemory="abordagemMemory"
        @recebeAbordagem="recebeAbordagem"
      />
    </div>
  </div>
</template>

<script>
import OpcaoLinguagem from "@/components/Integracao/OpcaoLinguagem.vue";
import OpcaoAbordagem from "@/components/Integracao/OpcaoAbordagem.vue";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "Abordagem",

  components: {
    OpcaoLinguagem,
    OpcaoAbordagem,
  },

  data() {
    return {
      keyBar: "",
      linguagemSelecionada: "",
      clicked: "",
      abordagem: {
        classic: false,
        lg: false,
        xg: false,
      },
      pythonLogo: require("/public/img/pythonblue.png"),
      pythonLogoLight: require("/public/img/pythonlight.png"),
      sqlLogo: require("/public/img/sqlblue.png"),
      sqlLogoLight: require("/public/img/sqllight.png"),
      rLogo: require("/public/img/Rblue.png"),
      rLogoLight: require("/public/img/Rlight.png"),
      classicLogo: require("/public/img/classicblue.png"),
      classicLogoLight: require("/public/img/classiclight.png"),
      lgLogoLight: require("/public/img/lglight.png"),
      xgLogoLight: require("/public/img/xglight.png"),
      lgLogo: require("/public/img/lg.png"),
      xgLogo: require("/public/img/xg.png"),
    };
  },
  methods: {
    recebeLing(linguagem) {
      this.clicked = linguagem;
      this.$emit("recebeLing", linguagem);
    },
    recebeAbordagem(abordagem) {
      this.abordagem[abordagem] = !this.abordagem[abordagem];
      this.$emit("recebeAbordagem", this.abordagem);
    },
  },

  computed: {
    ...mapWritableState(useSkynetMemoryStore, ["abordagemMemory"]),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.abordagemBox {
  width: 100%;
  min-height: 100%;
  @include flex-column();
  align-items: center;
}
.opcoesLinguagem {
  width: 25rem;
  min-height: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  margin: 1rem;

  @include mediaQueryMax(medium) {
    width: 25rem;
  }
  @include mediaQueryMax(small) {
    width: 15rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
  }
}

.tituloAbordagem {
  font-size: 1.3rem !important;
  color: #3b3b3b;
  font-weight: 500;
  margin-top: 0.3rem;
}

.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}
</style>