<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="`myChartBar${id}`"
      height="250px"
      width="500px"
    ></canvas>
  </div>
</template>

<script>
import { useSkynetStore } from "@/stores/skynet";
import { mapWritableState } from "pinia";
export default {
  name: "StackedSafraAmostra",
  props: {
    dataSafra: Array,
    id: Number,
    varAmostraQuantidades: Object,
  },
  data() {
    return {
      barWidth: 1,
      sum: 0,
      dataSetsCustom: {},
      dataFinal: [],
      labelSafra: [],
      labelAmostra: [],
      MyChartBar: null,
      colors: [
        "rgb(230, 25, 75, 0.4)",
        "rgb(60, 180, 75, 0.4)",
        "rgb(67, 99, 216, 0.4)",
        "rgb(245, 130, 49, 0.4)",
        "rgb(145, 30, 180, 0.4)",
        "rgb(70, 240, 240, 0.4)",
        "rgb(240, 50, 230, 0.4)",
        "rgb(188, 246, 12, 0.4)",
        "rgb(250, 190, 190, 0.4)",
        "rgb(0, 128, 128, 0.4)",
        "rgb(230, 190, 255, 0.4)",
        "rgb(154, 99, 36, 0.4)",
        "rgb(255, 250, 200, 0.4)",
        "rgb(128, 0, 0, 0.4)",
        "rgb(170, 255, 195, 0.4)",
        "rgb(128, 128, 0, 0.4)",
        "rgb(255, 216, 177, 0.4)",
        "rgb(0, 0, 117, 0.4)",
        "rgb(128, 128, 128, 0.4)",
        "rgb(255, 225, 25, 0.4)",
        "rgb(175, 255, 0, 0.4)",
        "rgb(175, 175, 0, 0.4)",
        "rgb(175, 0, 215, 0.4)",
        "rgb(135, 215, 255, 0.4)",
        "rgb(95, 0, 0, 0.4)",
        "rgb(0, 175, 255, 0.4)",
        "rgb(255, 0, 255, 0.4)",
        "rgb(0, 0, 95, 0.4)",
        "rgb(95, 0, 95, 0.4)",
        ,
      ],
      hoverColors: [
        "rgb(230, 25, 75, 0.8)",
        "rgb(60, 180, 75, 0.8)",
        "rgb(67, 99, 216, 0.8)",
        "rgb(245, 130, 49, 0.8)",
        "rgb(145, 30, 180, 0.8)",
        "rgb(70, 240, 240, 0.8)",
        "rgb(240, 50, 230, 0.8)",
        "rgb(188, 246, 12, 0.8)",
        "rgb(250, 190, 190, 0.8)",
        "rgb(0, 128, 128, 0.8)",
        "rgb(230, 190, 255, 0.8)",
        "rgb(154, 99, 36, 0.8)",
        "rgb(255, 250, 200, 0.8)",
        "rgb(128, 0, 0, 0.8)",
        "rgb(170, 255, 195, 0.8)",
        "rgb(128, 128, 0, 0.8)",
        "rgb(255, 216, 177, 0.8)",
        "rgb(0, 0, 117, 0.8)",
        "rgb(128, 128, 128, 0.8)",
        "rgb(255, 225, 25, 0.8)",
        "rgb(175, 255, 0, 0.8)",
        "rgb(175, 175, 0, 0.8)",
        "rgb(175, 0, 215, 0.8)",
        "rgb(135, 215, 255, 0.8)",
        "rgb(95, 0, 0, 0.8)",
        "rgb(0, 175, 255, 0.8)",
        "rgb(255, 0, 255, 0.8)",
        "rgb(0, 0, 95, 0.8)",
        "rgb(95, 0, 95, 0.8)",
        ,
      ],
    };
  },

  methods: {
    setLarguraBarra() {
      const numBars = this.labelSafra.length;
      if (numBars < 5) {
        this.barWidth = 0.2
      }
    },

    setDataset() {
      this.dataSetsCustom = {};
      for (const [index, [key, value]] of Object.entries(
        Object.entries(this.dataSafra)
      )) {
        this.labelSafra.push(this.dataSafra[key].Safra);
        for (let val = 0; val < this.varAmostraQtd; val++) {
          if (this.dataSetsCustom[val] == undefined) {
            this.dataSetsCustom[val] = [];
          }
          if (value.Principal[val] == undefined) {
            this.dataSetsCustom[val].push(null);
          } else {
            this.dataSetsCustom[val].push(value.Principal[val].Quantidade);
            this.labelAmostra.push(value.Principal[val].Valor);
          }
        }
      }
      this.setLarguraBarra()

      for (let i in this.dataSetsCustom) {
        this.dataFinal.push({
          label: this.labelAmostra[i],
          data: this.dataSetsCustom[i],
          backgroundColor: this.colors[i],
          hoverBackgroundColor: this.hoverColors[i],
          barPercentage: this.barWidth,
        });
      }
    },

    createChart() {
      const ctx = document.getElementById(`myChartBar${this.id}`);
      this.myChartBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labelSafra,
          datasets: this.dataFinal,
        },

        options: {
          tooltips: {
            displayColors: true,
            callbacks: {
              mode: "x",
            },
          },
          scales: {
            xAxes: [
              {
                offset: true,
                ticks: {
                  minRotation: 30,
                  lineHeight: 1,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Safra",
                },
                stacked: true,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  lineHeight: 0.8,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Frequência",
                },
                type: "linear",
              },
            ],
          },
          responsive: false,
          maintainAspectRatio: true,
          legend: {
            position: "top",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          hover: {
            mode: "nearest",
            intersect: false,
          },
        },
      });
    },
  },

  created() {
    this.setDataset();
  },

  mounted() {
    if (this.MyChartBar != null) {
      this.MyChartBar.destroy();
    }
    this.createChart();
  },
  computed: {
    ...mapWritableState(useSkynetStore, ["varAmostraQtd"]),
  },
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  cursor: pointer;
  justify-content: center;
}
.canvas {
  display: flex;
  
  @include mediaQueryMax(small) {
      max-width: 100%;
      // max-height: 60%;
  }
}
</style>