<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="`myChartBar${id}`"
      height="250px"
      width="500px"
    ></canvas>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";

export default {
  name: "MixedSafraAmostra",
  props: {
    data: Array,
    id: Number,
  },
  data() {
    return {
      sum: 0,
      dataSetsCustom: {},
      dataFinal: [],
      labelSafra: [],
      labelSafraFilter: [],
      labelAmostra: [],
      MyChartBar: null,
      colors: [
        "rgba(255, 99, 132, 0.4)",
        "rgba(75, 192, 192, 0.4)",
        "rgba(255, 159, 64, 0.4)",
        "rgba(153, 102, 255, 0.4)",
        "rgba(255, 206, 86, 0.4)",
        "rgba(54, 162, 235, 0.4)",
      ],
      hoverColors: [
        "rgba(255, 99, 132, 0.8)",
        "rgba(75, 192, 192, 0.8)",
        "rgba(255, 206, 86, 0.8)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(255, 159, 64, 0.8)",
        "rgba(54, 162, 235, 0.8)",
      ],
    };
  },

  methods: {
    setDataset() {
      this.dataSetsCustom = {};
      let maus = "";
      if (this.respMau == "Valor_0") {
        maus = "0";
      }
      if (this.respMau == "Valor_1") {
        maus = "1";
      }

      for (const [index, [key, value]] of Object.entries(
        Object.entries(this.data)
      )) {
        this.labelSafra.push(value.Safra);
        this.labelSafraFilter = this.labelSafra.filter(
          (item, index) => this.labelSafra.indexOf(item) === index
        );
        for (let i in value.Principal) {
          for (let j in value.Principal[i].Secundaria) {
            if (value.Principal[i].Secundaria[j].Valor == maus) {
              if (this.dataSetsCustom[value.Principal[i].Valor] == undefined) {
                this.dataSetsCustom[value.Principal[i].Valor] = {};
              }
              if (
                this.dataSetsCustom[value.Principal[i].Valor][value.Safra] ==
                undefined
              ) {
                this.dataSetsCustom[value.Principal[i].Valor][value.Safra] = [];
              }
              this.dataSetsCustom[value.Principal[i].Valor][value.Safra].push(
                (
                  value.Principal[i].Secundaria[j]
                    .RepresentatividadeContextoLocal * 100
                ).toFixed(2)
              );

              for (let k in this.dataSetsCustom[value.Principal[i].Valor]) {
                if (
                  value.Principal[i].Secundaria[j].Valor == "NA" &&
                  value.Principal[i].Secundaria[j].length < 2
                ) {
                  const contextoLocal = Number.NA;
                  this.dataSetsCustom[value.Principal[i].Valor].push(
                    contextoLocal
                  );
                }
              }
            }
          }
        }
      }
      let aux = [];
      for (const [index, [key, value]] of Object.entries(
        Object.entries(this.dataSetsCustom)
      )) {
        aux = [];
        for (let i in this.labelSafraFilter) {
          if (
            value[this.labelSafraFilter[i]] != undefined &&
            this.labelSafraFilter[i] in value
          ) {
            aux.push(value[this.labelSafraFilter[i]][0]);
          } else {
            aux.push(null);
          }
        }
        this.dataFinal.push({
          label: key,
          data: aux,
          backgroundColor: this.colors[index],
          hoverBackgroundColor: this.hoverColors[index],
          borderColor: this.colors[index],
          borderDash: [5, 5],
          fill: false,
        });
      }
    },
    createChart() {
      const ctx = document.getElementById(`myChartBar${this.id}`);
      this.myChartBar = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.labelSafraFilter,
          datasets: this.dataFinal,
        },
        options: {
          elements: {
            point: {
              radius: 2.3,
            },
          },
          tooltips: {
            displayColors: true,
            callbacks: {
              mode: "x",
            },
          },
          scales: {
            xAxes: [
              {
                offset: true,
                ticks: {
                  minRotation: 30,
                  lineHeight: 1,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Safra",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  stepSize: 10,
                  lineHeight: 0.8,
                  min: 0,
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Taxa de Maus",
                },
                type: "linear",
              },
            ],
          },
          // scaleBeginAtZero: true,
          responsive: false,
          maintainAspectRatio: false,
          legend: {
            position: "top",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          tooltips: {
            mode: "nearest",
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";

                if (label) {
                  label += ": ";
                }
                label += tooltipItem.yLabel + "%";
                return label;
              },
            },
          },
          hover: {
            mode: "point",
            intersect: false,
          }
        },
      });
    },
  },

  created() {
    this.setDataset();
  },

  mounted() {
    if (this.MyChartBar != null) {
      this.MyChartBar.destroy();
    }
    this.createChart();
  },
  computed: {
    ...mapWritableState(useSkynetMemoryStore, ["respMau"]),
  },
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  cursor: pointer;
  justify-content: center;
}
.canvas {
  display: flex;

  @include mediaQueryMax(small) {
      max-width: 100%;
      // max-height: 60%;
  }
}
</style>